// TODO rename to page paths

const pageLinks = {
    home: "/",
    wildflowerbelt: "/wildflowerbelt",
    contact: "/contact",
    about: "/about",
    orderConfirmation: "/order-confirmation",
}

module.exports = pageLinks;